import styles from './MorePage.module.scss';

import {
  CircularProgress,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomBackground } from '@work4all/components/lib/components/CustomBackground';

import {
  checkModuleRight,
  useHiddenEntities,
  useIsCustomBackgroundActive,
  useModuleRights,
  useUser,
} from '@work4all/data';
import { useDocumentClasses } from '@work4all/data/lib/hooks/document-classes';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';

import { NavGroup } from '../../components/more/nav-group/NavGroup';
import { Outline } from '../../components/more/outline/Outline';
import { TopBar } from '../../components/more/top-bar/TopBar';

import { isFavoriteLinksFolder } from './data/favorite-links';
import { useFavoriteLinks } from './data/favorite-links/favorite-links-context';
import { filterNavGroups } from './filter-nav-groups';
import {
  IsFavoriteLinkFunction,
  IsFavoriteLinkProvider,
} from './is-favorite-link-context';
import {
  NavigationCategory,
  NavigationGroup,
  NavigationLink,
  NavigationSubgroup,
} from './types';
import { useControlCenterSchema } from './use-control-center-schema/use-control-center-schema';

export function MorePage() {
  const schema = useControlCenterSchema();

  if (!schema.data) {
    return (
      <div className={styles.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return <MorePageInner navigationConfig={schema} />;
}
export function MorePageInner(props: {
  navigationConfig: { data: NavigationCategory[] };
}) {
  const { t } = useTranslation();
  const { isHidden } = useHiddenEntities();
  const user = useUser();
  const isAdmin = user.isMaster;
  const { rights } = useModuleRights();

  const { navigationConfig } = props;

  const md = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const { favoriteLinks, addFavoriteLink, removeFavoriteLinkByHref } =
    useFavoriteLinks();

  const isFavorite = useMemo<IsFavoriteLinkFunction>(() => {
    const allLinksFlat = favoriteLinks.flatMap((linkOrFolder) => {
      if (isFavoriteLinksFolder(linkOrFolder)) {
        return linkOrFolder.links;
      } else {
        return [linkOrFolder];
      }
    });

    const hrefs = new Set(allLinksFlat.map((link) => link.href));

    return (href) => {
      return hrefs.has(href);
    };
  }, [favoriteLinks]);

  const isCustomBackgroundActive = useIsCustomBackgroundActive();

  const documentClasses = useDocumentClasses();

  // Translate the names and sort all items alphabetically.
  const navigationCategories = useMemo<NavigationCategory[]>(() => {
    if (!navigationConfig.data) return [];
    return (
      navigationConfig.data as NavigationCategory[]
    ).map<NavigationCategory>((category) => {
      return {
        ...category,
        groups: category.groups
          .map<NavigationGroup>((group) => {
            const subgroups = group.subgroups
              .map<NavigationSubgroup>((subgroup) => {
                const links = subgroup.links
                  .map<NavigationLink>((link) => {
                    function isDisabled(link: NavigationLink): boolean {
                      // There are multiple reasons for why a link might be
                      // disabled.

                      // First check if the navigation tab requires admin
                      // rights.
                      if (category.adminRightsNeeded && !isAdmin) {
                        return true;
                      }

                      // If required modules are configured, check them.
                      if (link.moduleAccessRightType) {
                        // If modules are configured they supersede implied
                        // related entity rights. If the module right is not configured on the link,
                        // do not disable the link.
                        return !checkModuleRight(
                          rights,
                          link.moduleAccessRightType as ModuleAccessRightType
                        );
                      }

                      // At last check the related entity rights.
                      const entityTypes = link.relatedEntitiesEN ?? [];

                      return entityTypes.some((entityType) =>
                        isHidden(entityType as Entities)
                      );
                    }

                    const href = link.href?.trim() ?? '';
                    const isLinkEmpty = href === '' || href === '#';
                    const isPlaceholder = link?.placeholder;

                    return {
                      disabled:
                        isDisabled(link) || isLinkEmpty || isPlaceholder,
                      name: t(link.name),
                      href: href,
                    };
                  })
                  // Duplicate document page links using document classes.
                  .flatMap((link) => {
                    // Match the link's href against list page regex to
                    // detect links to list pages.

                    const listPageHrefRegex =
                      /^\/more\/entity\/(?<entityType>[^/?]+)/;

                    const result = listPageHrefRegex.exec(link.href);

                    if (result === null) {
                      return link;
                    }

                    const { entityType } = result.groups;

                    if (entityType !== Entities.document) {
                      return link;
                    }

                    const uniqueDocumentClassNames = [
                      ...new Set(documentClasses.map((dc) => dc.name)),
                    ];

                    return [
                      link,
                      ...uniqueDocumentClassNames.map((documentClassName) => {
                        const entityType = Entities.document;
                        const entityVariant = documentClassName;
                        const entityVariantEncoded =
                          encodeURIComponent(entityVariant);
                        const entityName = `${entityType}(${entityVariantEncoded})`;

                        const documentClassLink: NavigationLink = {
                          disabled: isHidden({ entityType, entityVariant }),
                          href: `/more/entity/${entityName}`,
                          name: entityVariant,
                        };

                        return documentClassLink;
                      }),
                    ];
                  })
                  .sort((a, b) => a.name.localeCompare(b.name));

                return {
                  disabled: links.every((link) => link.disabled),
                  name: subgroup.name ? t(subgroup.name) : null,
                  links: links,
                };
              })
              .sort((a, b) => a.name?.localeCompare(b.name));

            return {
              disabled: subgroups.every((subgroup) => subgroup.disabled),
              name: t(group.name),
              subgroups: subgroups,
            };
          })
          .map((group) => {
            return {
              ...group,
              subgroups: group.subgroups.filter(
                (subgroup) => subgroup.links.length > 0
              ),
            };
          })
          .filter((group) => {
            return group.subgroups.length > 0;
          })
          .sort((a, b) => a.name.localeCompare(b.name)),
      };
    });
  }, [navigationConfig.data, t, isAdmin, rights, isHidden, documentClasses]);

  const [activeCategoryName, setActiveCategoryName] = useState<string | null>(
    () => {
      return navigationCategories[0]?.name ?? null;
    }
  );

  const activeCategory = useMemo(() => {
    return navigationCategories.find((category) => {
      return category.name === activeCategoryName;
    });
  }, [activeCategoryName, navigationCategories]);

  const [activeGroup, setActiveGroup] = useState<string | null>(null);

  const navigationGroups = useMemo(() => {
    return activeCategory?.groups ?? [];
  }, [activeCategory]);

  const [filter, setFilter] = useState('');
  const filterTrimmed = filter.trim();

  const matchedGroups = useMemo(() => {
    return filterNavGroups(navigationGroups, filterTrimmed);
  }, [filterTrimmed, navigationGroups]);

  const onItemFavoriteChange = useCallback(
    (item: NavigationLink, favorite: boolean) => {
      if (favorite) {
        addFavoriteLink({ link: { name: item.name, href: item.href } });
      } else {
        removeFavoriteLinkByHref(item.href);
      }
    },
    [addFavoriteLink, removeFavoriteLinkByHref]
  );

  return (
    <IsFavoriteLinkProvider value={isFavorite}>
      <div>
        <CustomBackground />
        <TopBar
          filter={filter}
          onFilterChange={setFilter}
          showAlert={activeCategory?.adminRightsNeeded === true && !isAdmin}
          tabs={
            <Tabs
              value={activeCategoryName}
              onChange={(_, value) => {
                setActiveCategoryName(value);
                setActiveGroup(null);
              }}
            >
              {navigationCategories.map((category) => {
                const { name } = category;
                return <Tab key={name} label={t(name)} value={name} />;
              })}
            </Tabs>
          }
        />

        <div className={styles.grid}>
          <div className={styles.outlineWrapper}>
            <div className={clsx(styles.stickyTop)}>
              <Outline
                groups={matchedGroups}
                selectedGroup={activeGroup}
                onSelectedGroupChange={setActiveGroup}
              />
            </div>
          </div>

          <div className={styles.groupsWrapper}>
            {matchedGroups.map((group) => {
              // If the outline is visible (breakpoints.md and up), when a group
              // is selected in the outline, only show this group.
              if (md && activeGroup !== null && activeGroup !== group.name) {
                return null;
              }

              // If the groups have actually been searched and nothing was
              // matched, hide the group. `matches` will be undefined if no
              // match was performed (if not currently filtering).
              if (group.matches === 0) {
                return null;
              }

              return (
                <NavGroup
                  key={group.name}
                  group={group}
                  onItemFavoriteChange={onItemFavoriteChange}
                />
              );
            })}
          </div>

          <div className={styles.infoWrapper}>
            <div
              className={clsx(
                styles.info,
                styles.stickyTop,
                isCustomBackgroundActive && styles.dark
              )}
            >
              <section>
                <Typography variant="h4">
                  {t('MORE.MODULES_AND_FUNCTIONS')}
                </Typography>

                {t('MORE.MODULES_AND_FUNCTIONS.EXPLAINER')
                  .split('\n')
                  .map((txt) => (
                    <Typography key={txt} variant="body2">
                      {txt}
                    </Typography>
                  ))}
              </section>

              {favoriteLinks.length === 0 && (
                <section>
                  <Typography variant="h4">
                    {t('MORE.INDIVIDUAL_NAVIGATION')}
                  </Typography>

                  <Typography variant="body2">
                    {t('MORE.INDIVIDUAL_NAVIGATION.EXPLAINER')}
                  </Typography>
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
    </IsFavoriteLinkProvider>
  );
}
