import styles from './TextmarkPicker.module.scss';

import Typography from '@mui/material/Typography';
import { useObservableState } from 'observable-hooks';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import {
  SelectableTree,
  TreeNode,
} from '../../../../dataDisplay/tree/SelectableTree';
import { GetEditorResult } from '../../../../input/format-text/TextEditor/types';
import { DEFAULT_HTML_FONT_SIZE } from '../../../../input/format-text/TextEditor/utils/html-parser';
import { FilterTextInput } from '../../components';
import { useTextmarkData } from '../hooks/use-textmark-data';
import { Textmark, TextmarkType } from '../types';

export type TextmarkPickerResult = {
  textmark: Textmark;
  newEditorValue: string;
  textmarkMarkup: string;
};

export interface TextmarkPickerProps {
  editor?: GetEditorResult;
  onChange?: (value: TextmarkPickerResult) => void;
  type?: TextmarkType;
  iconSize?: 'small' | 'medium' | 'large';
}

export function TextmarkPicker(props: TextmarkPickerProps) {
  const { editor, onChange, type = 'email' } = props;

  const textmarkData = useTextmarkData({
    type,
  });

  const textmarkList = useMemo<Textmark[]>(() => {
    function flattenList(data: Textmark[]) {
      return data.map((x) => (x.subItems ? flattenList(x.subItems) : x)).flat();
    }
    return flattenList(textmarkData) || [];
  }, [textmarkData]);

  const { t } = useTranslation();

  const handleChange = useCallback(
    (textmark: Textmark) => {
      const textmarkMarkup = `<span style="font-size: ${DEFAULT_HTML_FONT_SIZE};"><span class="textmarke fr-deletable blockinput" data-item="${
        textmark.accessor
      }" data-name="${textmark.name}" >${t(textmark.name)}</span></span>`;

      if (editor) {
        editor?.html?.insert(textmarkMarkup);
      }

      if (onChange) {
        onChange({
          textmark,
          newEditorValue: editor?.html?.get(),
          textmarkMarkup,
        });
      }
    },
    [editor, onChange, t]
  );

  const getTextmarksById = useCallback(
    (selectedId: string): Textmark => {
      const foundElements = textmarkList.find(
        (textmark) => selectedId === textmark.id
      );
      return foundElements || null;
    },
    [textmarkList]
  );

  const [searchString, setSearchString] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    ''
  );

  const treeData = useMemo<TreeNode[]>(() => {
    const toTree = (data: Textmark[]) => {
      return data
        .map((el) => {
          const result: TreeNode = {
            id: el.id.toString(),
            label: t(el.name),
            children: el.subItems
              ? toTree(
                  [...el.subItems].sort((a, b) =>
                    t(a.name) > t(b.name) ? 1 : t(b.name) > t(a.name) ? -1 : 0
                  )
                )
              : undefined,
          };
          return result;
        }, [])
        .sort((a, b) => a.label.toString().localeCompare(b.label.toString()));
    };

    //return tree
    if (searchString.trim() === '') return toTree(textmarkData || []);

    //return flat list
    return (textmarkData || [])
      .map((el) => ({
        parent: el,
        children:
          el.subItems?.filter((el) =>
            t(el.name).toLowerCase().includes(searchString.toLowerCase())
          ) ?? [],
      }))
      .filter((item) => item.children.length > 0)
      .flatMap(({ children }) => {
        return children.map((el) => ({
          id: el.id.toString(),
          label: (
            <Typography component="div">
              <span>{t(el.name)}</span>
            </Typography>
          ),
        }));
      });
  }, [textmarkData, searchString, t]);

  const selectableTreeRef = useRef<HTMLDivElement>(null);

  const handleKeyNavigation = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'ArrowDown') {
        setTimeout(() => {
          if (e?.target) {
            const htmlTarget = e.target as HTMLElement;
            htmlTarget.blur();
          }
          if (selectableTreeRef?.current?.firstChild) {
            const htmlTarget = selectableTreeRef.current
              .firstChild as HTMLElement;
            htmlTarget.focus();
          }
        });
      }
    },
    []
  );

  return (
    <div>
      <div className={styles.wrapper}>
        <FilterTextInput
          placeholder={t('PICKER.SEARCH.DEFAULT')}
          onChange={(val) => {
            setSearchString(val);
          }}
          onKeyDown={handleKeyNavigation}
        />
      </div>
      <div className={styles.treeWrapper} ref={selectableTreeRef}>
        <SelectableTree
          multiple={false}
          selectable="leaf"
          data={treeData}
          selected={null}
          onChange={(ids: string | string[]) => {
            const selected = Array.isArray(ids) ? ids : [ids];
            handleChange(getTextmarksById(selected[0]));
          }}
        />
      </div>
    </div>
  );
}
