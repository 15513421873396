import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ModuleAccessRightDenyReason } from '../Enums/ModuleAccessRightDenyReason.enum';
import { ModuleAccessRightType } from '../Enums/ModuleAccessRightType.enum';
import { ObjectType } from '../Enums/ObjectType.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

export class ModuleAccessRight<T extends EMode = EMode.entity> {
  /** Alias: type */
  type?: ModuleAccessRightType;
  /** Alias: userCode */
  userId?: number;
  /** Alias: tenantCode */
  tenantCode?: number;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: moduleAccessRightDenyReason */
  moduleAccessRightDenyReason?: ModuleAccessRightDenyReason;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: hasAccess */
  hasAccess?: boolean;
  /** Alias: allowedForAdmin */
  allowedForAdmin?: boolean;
  /** Alias: subObjectType */
  subObjectType?: string;

  __typename?: string;
}

const fields: FieldDefinitions<ModuleAccessRight> = {
  type: {
    alias: 'type',
  },
  userId: {
    alias: 'userCode',
  },
  tenantCode: {
    alias: 'tenantCode',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  moduleAccessRightDenyReason: {
    alias: 'moduleAccessRightDenyReason',
  },
  objectType: {
    alias: 'objectType',
  },
  hasAccess: {
    alias: 'hasAccess',
  },
  allowedForAdmin: {
    alias: 'allowedForAdmin',
  },
  subObjectType: {
    alias: 'subObjectType',
  },
  __typename: {
    alias: '__typename',
  },
};

export const moduleAccessRightEntityDefinition: EntitiyDefinition<ModuleAccessRight> =
  {
    local: {},
    remote: {
      queryName: 'getModuleAccessRights',
      fragmentName: 'ModuleAccessRight',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
