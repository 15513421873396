import { gql, useQuery } from '@apollo/client';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

const READ_MAIL = gql`
  query ReadMail($mailboxId: String!, $folderId: String!, $mailId: String!) {
    readMail(mailboxId: $mailboxId, folderId: $folderId, mailId: $mailId) {
      id
      renderableBody
    }
  }
`;

export const useReadMail = (props: {
  mailboxId: string;
  folderId: string;
  mailId: string | null;
}) => {
  return useQuery<{ readMail: MailboxContent<EMode.entity> }>(READ_MAIL, {
    variables: props,
    skip: !props.mailId,
  });
};
