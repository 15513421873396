import styles from './EditModeContent.module.scss';

import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { inlineMentions } from '../../../../../../input/format-text/TextEditor/plugins/mention/utils';
import { CommentEditor } from '../../CommentEditor';
import { useEditCommentMutation, useProcessHtml } from '../hooks';

import { ViewContentProps } from './ViewContent';

type EditModeContentProps = Pick<
  ViewContentProps,
  'event' | 'onEditFinished' | 'canAddOrInsertImage'
>;

export const EditModeContent = ({
  event,
  onEditFinished,
  canAddOrInsertImage,
}: EditModeContentProps) => {
  const { t } = useTranslation();
  const html = useProcessHtml(event.text);

  const [comment, setComment] = useState(html);

  const [editComment] = useEditCommentMutation({
    onCompleted() {
      onEditFinished();
    },
  });

  const handleEditComment = () => {
    editComment({
      variables: {
        codeOrId: event.id,
        objectType: event.objectType,
        comment: inlineMentions(comment),
      },
    });
  };

  return (
    <div className={styles.container}>
      <CommentEditor
        value={comment}
        setValue={(value) => {
          setComment(value);
        }}
        editorConf={{
          placeholderText: '',
          height: 52,
          heightMin: 150,
          heightMax: 300,
          toolbarButtons: {
            moreText: {
              buttons: [
                'bold',
                'underline',
                'italic',
                'strikeThrough',
                'clearFormatting',
                'insertImage',
              ],
              buttonsVisible: 6,
            },
          },
        }}
        canAddOrInsertImage={canAddOrInsertImage}
      />

      <div>
        <Button
          size="large"
          color="primary"
          type="button"
          onClick={onEditFinished}
        >
          {t('COMMON.ABORT')}
        </Button>

        <Button
          size="large"
          color="primary"
          type="button"
          onClick={handleEditComment}
        >
          {t('ALERTS.BTN_SAVE')}
        </Button>
      </div>
    </div>
  );
};
