import styles from './PreviewTextEditor.module.scss';

import { Add } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import Linkify from 'linkify-react';
import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

import { useUsersContext } from '@work4all/data/lib/hooks/users/users-context';

import { BaseActionButton } from '../../../../input/base-action-button/BaseActionButton';
import { TextEditor } from '../../../../input/format-text/TextEditor';
import {
  extractMentions,
  ExtractMentionsResolveFn,
} from '../../../../input/format-text/TextEditor/plugins/mention/utils';
import { LabeledInput } from '../../../../input/labeled-input';
import { Body1 } from '../../../../typography/body1/Body1';

export type TextEditor = {
  open: () => void;
  close: () => void;
};

interface EditorProps {
  value?: string;
  disabled?: boolean;
  decorator?: ReactNode;
  previewMode?: 'rich' | 'text';
  onClick?: () => void;
  onClose?: () => void;
  onChange: (value: string) => void;
}

const processHtml = (html: string, resolve: ExtractMentionsResolveFn) => {
  const withMentions = extractMentions(html, resolve);
  const sanitized = sanitizeHtml(withMentions, {
    allowedTags: [
      'img',
      'p',
      'br',
      'div',
      'span',
      'table',
      'tr',
      'td',
      'th',
      'strong',
      'u',
      'em',
      'i',
      's',
      'li',
      'ol',
      'ul',
      'a',
    ],
    allowedAttributes: {
      img: ['src', 'style', 'alt'],
      a: ['href'],
      '*': ['style'],
    },
  });

  return sanitized;
};

export const PreviewTextEditor = forwardRef(function PreviewTextEditor(
  props: EditorProps,
  ref: ForwardedRef<TextEditor>
) {
  const {
    value,
    disabled = true,
    decorator,
    previewMode = 'text',
    onClick,
    onClose,
    onChange,
  } = props;

  const [editMode, setEditMode] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setEditMode(true);
      },
      close: () => {
        setEditMode(false);
        onClose?.();
      },
    }),
    []
  );

  const handleClick = () => {
    onClick?.();
    if (!disabled) {
      setEditMode(true);
    }
  };

  const handleBlur = () => {
    setEditMode(false);
    onClose?.();
  };

  const renderLink = ({ attributes, content }) => {
    const { href, ...props } = attributes;
    return (
      <a target="blank" href={href} {...props}>
        {content}
      </a>
    );
  };

  const { t } = useTranslation();

  const { usersById } = useUsersContext();

  const resolve = useCallback<ExtractMentionsResolveFn>(
    ({ id }) => {
      const user = usersById[id];

      if (user && user.displayName) {
        return { text: user.displayName };
      }
    },
    [usersById]
  );

  const html = useMemo(() => {
    return processHtml(value, resolve);
  }, [value, resolve]);

  if (previewMode === 'rich') {
    const toolbarButtons = {
      moreText: {
        buttons: [
          'undo',
          'redo',
          '|',
          'bold',
          'underline',
          'strikeThrough',
          'italic',
          '|',
          'clearFormatting',
        ],
        buttonsVisible: 7,
      },
      moreRich: {
        buttons: ['insertImage'],
        buttonsVisible: 0,
      },
    };
    return (
      <Box
        sx={{
          overflow: 'auto',
          padding: '0.5rem',
        }}
      >
        {editMode ? (
          <TextEditor
            value={value}
            config={{
              autofocus: true,
              events: {
                blur: handleBlur,
              },
              imageUpload: false,
              imagePaste: false,
              toolbarButtonsXS: toolbarButtons,
              toolbarButtonsSM: toolbarButtons,
              toolbarButtonsMD: toolbarButtons,
              toolbarButtonsLG: toolbarButtons,
              toolbarButtons: toolbarButtons,
            }}
            ignoreButtons={['insertImage']}
            onChange={onChange}
          />
        ) : (
          <div className={styles.previewWrapper}>
            {value ? (
              <div onClick={handleClick} className={styles.preview}>
                <Typography
                  component="div"
                  variant="body2"
                  color="text.primary"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </div>
            ) : (
              <Box justifySelf="baseline">
                <BaseActionButton
                  onClick={handleClick}
                  icon={<Add />}
                  title={t('MASK.ADD_TO')}
                />
              </Box>
            )}
            {decorator ? (
              <div className={styles.decorator}>{decorator}</div>
            ) : null}
          </div>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        overflow: 'auto',
        padding: '0.5rem',
      }}
    >
      {editMode ? (
        <LabeledInput
          className={styles.textEditor}
          defaultValue={value}
          onBlur={handleBlur}
          multiline
          onChange={(value) => {
            onChange(value.target.value);
          }}
        />
      ) : (
        <div className={styles.previewWrapper}>
          {value ? (
            <div onClick={handleClick} className={styles.preview}>
              <Linkify
                as="div"
                options={{
                  render: renderLink,
                }}
              >
                <Body1 className={styles.preLine}>{value}</Body1>
              </Linkify>
            </div>
          ) : (
            <Box justifySelf="baseline">
              <BaseActionButton
                onClick={handleClick}
                icon={<Add />}
                title={t('MASK.ADD_TO')}
              />
            </Box>
          )}
          {decorator ? (
            <div className={styles.decorator}>{decorator}</div>
          ) : null}
        </div>
      )}
    </Box>
  );
});
