import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IWordDocumentTemplatePickerProps,
  WordDocumentTemplatePicker,
} from '@work4all/components/lib/components/entity-picker/word-document-template-picker/WordDocumentTemplatePicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface WordDocumentTemplatePickerFieldProps
  extends Omit<IWordDocumentTemplatePickerProps<false>, 'multiple'> {
  error?: string;
}

export const WordDocumentTemplatePickerField = React.forwardRef<
  HTMLDivElement,
  WordDocumentTemplatePickerFieldProps
>(function WordDocumentTemplatePickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const popoverRef = useRef<EntityPickerPopover>(null);

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={<WordDocumentTemplatePicker {...pickerProps} multiple={false} />}
    >
      <PickerTargetButton
        ref={ref}
        value={value?.body ?? ''}
        label={t(`COMMON.WORDDOCUMENTTEMPLATE`)}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
