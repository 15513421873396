import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';

import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import { useCloneConvertErp } from './offers-table/hooks/use-clone-convert-erp';
import schema from './schemata/invoice-table-schema.json';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useErpHandlersTemplate } from './use-erp-handlers-template';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const InvoicesTable = React.forwardRef<TableInstance, IEntityTable>(
  function InvoicesTable(_props, ref) {
    const { t } = useTranslation();
    const { entity: entityType } = schema as never;
    const template = useErpHandlersTemplate();
    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      Invoice,
      never
    >({
      schema: schema as never,
      defaultSort,
      enableFooter: true,
    });

    const rowModifiers = useCallback(
      (value: Invoice) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isShade1: value.ra,
          isFaded: value.paid,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const tableStateBag = useTableStateBag();
    const { actions, component } = useCloneConvertErp({
      entity: Entities.invoice,
      data: useMemo(
        () =>
          tableStateBag.tableInstance?.selectedFlatRows.map(
            (x) => x.original as Invoice
          ),
        [tableStateBag]
      ),
    });

    const custom = useMemo(
      () => ({
        left: actions,
      }),
      [actions]
    );

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    return (
      <>
        {component}
        <EntityTable
          ref={ref}
          template={template}
          {...dataTable}
          actions={{
            custom,
            remove: {
              ...deleteConfig,
              canDeleteEntity: (id) => {
                const entity = dataTable.selectedEntities.find(
                  (item) => item.id === Number(id)
                );
                const canDelete = !entity.paid && !entity.ra;

                return {
                  value: canDelete,
                  preventMessage: canDelete
                    ? null
                    : t('ALERTS.OUTGOING_INVOICE_PAYMENTS_EXIST', {
                        number: entity?.number,
                      }),
                };
              },
            },
          }}
          prepareRowDisplayModifiers={rowModifiers}
          displayFooter
        />
      </>
    );
  }
);
