import { useEventCallback } from '@mui/material/utils';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { EmailTemplatesPopover, useDialogs } from '@work4all/components';
import { useEmailTemplates } from '@work4all/components/lib/hooks/use-email-templates';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../settings';
import { MaskConfig, MaskTemplateEntity } from '../../mask-overlay/types';
import {
  EmailMaskCustomParams,
  EmailOverlayController,
} from '../../mask-overlay/views/email/EmailOverlayController';

interface EmailParams {
  entityTemplate?: MaskTemplateEntity | null;
  params?: MaskConfig<EmailMaskCustomParams>['params'];
}

export interface EmailTemplateButtonContextProps {
  kind: EMailTemplateKind | EMailTemplateKind[];
  mainKind?: EMailTemplateKind;
  noTemplate?: boolean;
  isSplit?: boolean;
  getEmailParams: (
    template: EMailTemplate,
    context?: unknown
  ) => Promise<EmailParams>;
}

interface EmailTemplateButtonContextState {
  emailTempaltePickerFieldRef: React.MutableRefObject<undefined>;
  onClick: () => void;
  onArrowClick: () => void;
}

const EmailTemplateButtonContext =
  createContext<EmailTemplateButtonContextState | null>(null);

export const EmailTemplateButtonProvider = (
  props: PropsWithChildren<EmailTemplateButtonContextProps>
) => {
  const { children, noTemplate, kind, mainKind, isSplit, ...args } = props;

  const dialogs = useDialogs();

  const [pickEmailTemplateOpen, setPickEmailTemplateOpen] = useState(false);
  const emailTempaltePickerFieldRef = useRef();
  const templates = useEmailTemplates(kind);

  const { value: standardTemplates, set: setStandardTemplate } = useSetting(
    settings.standardEmailTemplates()
  );

  const sortedTemplates = useMemo(() => {
    return [...templates].sort((a, b) => {
      // Check if either a or b is a standard template
      const isAStandardTemplate =
        a.id === standardTemplates[a.eMailTemplateKind];
      const isBStandardTemplate =
        b.id === standardTemplates[b.eMailTemplateKind];

      // If one of them is a standard template, prioritize it on the list
      if (isAStandardTemplate && !isBStandardTemplate) {
        return -1;
      } else if (!isAStandardTemplate && isBStandardTemplate) {
        return 1;
      } else {
        // Get the index of eMailTemplateKind
        const indexA = kind.indexOf(a.eMailTemplateKind);
        const indexB = kind.indexOf(b.eMailTemplateKind);

        if (indexA === indexB) {
          // Sort alphabeticaly in groups defined by eMailTemplateKind
          return a.name.localeCompare(b.name);
        }

        return indexA - indexB;
      }
    });
  }, [kind, standardTemplates, templates]);

  const mappedTemplates = sortedTemplates.map((item) => {
    const isStandard = standardTemplates[item.eMailTemplateKind] === item.id;
    return { ...item, isStandard };
  });

  // ToDo: filter templates by file based language code (replace '0' with correct one)
  //       https://work4all.atlassian.net/browse/WW-3823
  const laguageBasedTemplates = mappedTemplates.filter(
    (template) => template.languageCode === 0
  );

  const mainLaguageBasedTemplates = laguageBasedTemplates.filter(
    (template) => template.eMailTemplateKind === mainKind
  );
  const restLaguageBasedTemplates = laguageBasedTemplates.filter(
    (template) => template.eMailTemplateKind !== mainKind
  );

  const openEmailMaskForTemplate = useEventCallback(
    async (template: EMailTemplate) => {
      const emailParams = await args.getEmailParams(template);
      if (!emailParams) return;

      const { entityTemplate, params } = emailParams;

      const initialView: IStackItem = {
        view: (
          <EmailOverlayController
            entity={Entities.eMail}
            id={null}
            template={entityTemplate}
            onAfterSave={closeMaskOverlay}
            params={{
              basedon: 'EmailTemplate',
              emailTemplate: template ? JSON.stringify(template) : undefined,
              forceNoTemplate: (template === null).toString(),
              ...params,
            }}
          />
        ),
      };

      const dialog = dialogs.open(NavigationOverlay, {
        initialView: initialView,
        close() {
          dialogs.close(dialog.id);
        },
      });

      function closeMaskOverlay() {
        dialogs.close(dialog.id);
      }
    }
  );

  const value = useMemo(() => {
    return {
      emailTempaltePickerFieldRef,
      onClick: () => {
        if (isSplit) {
          openEmailMaskForTemplate(laguageBasedTemplates[0]);
        } else if (laguageBasedTemplates.length > 1) {
          setPickEmailTemplateOpen(true);
        } else {
          openEmailMaskForTemplate(laguageBasedTemplates[0]);
        }
      },
      onArrowClick: () => {
        if (
          laguageBasedTemplates.length > 1 ||
          (laguageBasedTemplates.length === 1 && noTemplate)
        ) {
          setPickEmailTemplateOpen(true);
        } else {
          openEmailMaskForTemplate(laguageBasedTemplates[0]);
        }
      },
    };
  }, [isSplit, laguageBasedTemplates, noTemplate, openEmailMaskForTemplate]);

  return (
    <EmailTemplateButtonContext.Provider value={value}>
      {pickEmailTemplateOpen && (
        <EmailTemplatesPopover
          noTemplate={noTemplate}
          templates={
            mainKind ? restLaguageBasedTemplates : laguageBasedTemplates
          }
          mainTemplates={mainKind ? mainLaguageBasedTemplates : null}
          anchorEl={emailTempaltePickerFieldRef.current}
          onClose={() => setPickEmailTemplateOpen(false)}
          onTemplateClick={(template: EMailTemplate) => {
            setPickEmailTemplateOpen(false);
            openEmailMaskForTemplate(template);
          }}
          onStandardSelect={(template: EMailTemplate) => {
            setStandardTemplate({
              ...standardTemplates,
              [template.eMailTemplateKind]:
                standardTemplates[template.eMailTemplateKind] !== template.id
                  ? template.id
                  : null,
            });
          }}
        />
      )}

      {children}
    </EmailTemplateButtonContext.Provider>
  );
};

export const useEmailTemplateButton = () => {
  const state = useContext(EmailTemplateButtonContext);
  if (!state) {
    throw new Error(
      'Context is null. Make sure you wrapped your component with <EmailTemplateButtonContext />'
    );
  }
  return state;
};
