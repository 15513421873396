import styles from './ProjectCategoryClassPicker.module.scss';

import Typography from '@mui/material/Typography';
import { useObservableState } from 'observable-hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { useDataProvider } from '@work4all/data';

import { ProjectCategory } from '@work4all/models/lib/Classes/ProjectCategory.entity';
import { ProjectCategoryClass } from '@work4all/models/lib/Classes/ProjectCategoryClass.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Chip } from '../../../dataDisplay/chip/Chip';
import { ChipList } from '../../../dataDisplay/chip/ChipList';
import {
  SelectableTree,
  TreeNode,
} from '../../../dataDisplay/tree/SelectableTree';
import { FilterTextInput } from '../components';
import { IPickerProps } from '../types';
import { Selection } from '../utils/selection-model';

export type IProjectCategoryClassPickerProps<TMultiple extends boolean> = Omit<
  IPickerProps<ProjectCategory, TMultiple>,
  'data'
>;

type ProjectCategoryCategoryClassUnion = Pick<
  ProjectCategoryClass & ProjectCategory,
  'id' | 'name' | 'categoryList'
>;

export function ProjectCategoryClassPicker<TMultiple extends boolean>(
  props: IProjectCategoryClassPickerProps<TMultiple>
) {
  const requestData = useMemo<DataRequest>(() => {
    return {
      data: PROJECT_CAT_DATA,
      entity: Entities.projectCategoryClass,
      filter: props.prefilter,
      vars: {
        querySize: 9999,
      },
    };
  }, [props.prefilter]);

  const responseData = useDataProvider(requestData, false);
  const { t } = useTranslation();

  const filteredResponseData = useMemo<ProjectCategoryClass[]>(() => {
    return (
      responseData?.data?.filter(
        (category) => category.categoryList.length > 0
      ) ?? []
    );
  }, [responseData]);

  const categoryList = useMemo<ProjectCategory[]>(() => {
    return (
      filteredResponseData?.reduce(
        (curr, x) => (curr = curr?.concat(x.categoryList)),
        []
      ) || []
    );
  }, [filteredResponseData]);

  const getCategoriesById = useCallback(
    (selectedIds: string[]): Selection<ProjectCategory, TMultiple> => {
      const foundElements = categoryList[props.multiple ? 'filter' : 'find'](
        (category) => selectedIds.includes(category.id.toString())
      ) as Selection<ProjectCategory, TMultiple>;
      return foundElements || null;
    },
    [props.multiple, categoryList]
  );

  const [searchString, setSearchString] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    ''
  );

  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    if (props.multiple) {
      setSelectedIds(
        (props?.value as Selection<ProjectCategory, true>)?.map((x) =>
          x.id.toString()
        )
      );
    } else {
      setSelectedIds([
        (props?.value as Selection<ProjectCategory, false>)?.id?.toString(),
      ]);
    }
  }, [props.multiple, props?.value]);

  const nodeMap = useMemo(() => {
    const map = new Map<
      string,
      ProjectCategoryCategoryClassUnion & {
        parent: ProjectCategoryCategoryClassUnion;
      }
    >();
    const arrayToMap = (
      elements: ProjectCategoryCategoryClassUnion[],
      map: Map<
        string,
        ProjectCategoryCategoryClassUnion & {
          parent: ProjectCategoryCategoryClassUnion;
        }
      >,
      parent?: ProjectCategoryCategoryClassUnion
    ) => {
      elements.forEach((el) => {
        map.set(el.id.toString(), { ...el, parent });
        if (el.categoryList?.length > 0) {
          arrayToMap(el.categoryList, map, el);
        }
      });
    };
    arrayToMap(filteredResponseData, map);
    return map;
  }, [filteredResponseData]);

  const treeData = useMemo<TreeNode[]>(() => {
    const toTree = (data: ProjectCategoryCategoryClassUnion[]) => {
      return data.map((el) => {
        const result: TreeNode = {
          id: el.id.toString(),
          label: el.name,
          children: el.categoryList
            ? toTree(
                [...el.categoryList].sort((a, b) =>
                  a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                )
              )
            : undefined,
        };
        return result;
      }, []);
    };

    //return tree
    if (searchString.trim() === '') return toTree(filteredResponseData || []);

    //return flat list
    return (filteredResponseData || [])
      .map((el) => ({
        parent: el,
        children:
          el.categoryList?.filter((el) =>
            el.name.toLowerCase().includes(searchString.toLowerCase())
          ) ?? [],
      }))
      .filter((item) => item.children.length > 0)
      .flatMap(({ parent, children }) => {
        return children.map((el) => ({
          id: el.id.toString(),
          label: (
            <Typography component="div">
              <span>{el.name}</span>
              <span
                className={styles.secondaryItemLabel}
              >{` | ${parent.name}`}</span>
            </Typography>
          ),
        }));
      });
  }, [filteredResponseData, searchString]);

  const selectableTreeRef = useRef<HTMLDivElement>(null);

  const handleKeyNavigation = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'ArrowDown') {
        setTimeout(() => {
          if (e?.target) {
            const htmlTarget = e.target as HTMLElement;
            htmlTarget.blur();
          }
          if (selectableTreeRef?.current?.firstChild) {
            const htmlTarget = selectableTreeRef.current
              .firstChild as HTMLElement;
            htmlTarget.focus();
          }
        });
      }
    },
    []
  );

  return (
    <div>
      {props.multiple && selectedIds?.length > 0 && (
        <div className={styles.wrapper}>
          <ChipList>
            {selectedIds?.map((id) => {
              const data = nodeMap.get(id.toString());
              return (
                <Chip
                  key={id}
                  maxWidth={18}
                  label={`${data?.parent?.name} / ${data?.name}`}
                  handleDelete={() => {
                    const selected = selectedIds.filter(
                      (x) => x !== id.toString()
                    );
                    setSelectedIds(selected);
                    if (props.onChange) {
                      props.onChange(getCategoriesById(selected));
                    }
                  }}
                />
              );
            })}
          </ChipList>
        </div>
      )}

      <div className={styles.wrapper}>
        <FilterTextInput
          placeholder={t('PICKER.SEARCH.DEFAULT')}
          onChange={(val) => {
            setSearchString(val);
          }}
          onKeyDown={handleKeyNavigation}
        />
      </div>
      <div className={styles.treeWrapper} ref={selectableTreeRef}>
        <SelectableTree
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          multiple={props.multiple as any}
          selectable="leaf"
          data={treeData}
          selected={selectedIds}
          onChange={(ids: string | string[]) => {
            const selected = Array.isArray(ids) ? ids : [ids];
            setSelectedIds(selected);
            if (props.onChange) {
              props.onChange(getCategoriesById(selected));
            }
          }}
        />
      </div>
    </div>
  );
}

const PROJECT_CAT_DATA: ProjectCategoryClass<EMode.query> = {
  id: null,
  name: null,
  categoryList: [
    {
      id: null,
      name: null,
      topLevelCategory: {
        id: null,
        name: null,
      },
    },
  ],
};
