import { gql, useQuery } from '@apollo/client';
import React, { useContext, useMemo } from 'react';

import { ModuleAccessRight } from '@work4all/models/lib/Classes/ModuleAccessRight.entity';

export interface ModuleRightsCtx {
  loading: boolean;
  rights: ModuleAccessRight[];
}
const empty = [];

const ModuleRightsContext = React.createContext<ModuleRightsCtx>({
  loading: true,
  rights: empty,
});

const MODULE_RIGHTS_QUERY = gql`
  query GetModuleAccessRights {
    getModuleAccessRights {
      type
      userId: userCode
      tenantCode
      sdObjType
      moduleAccessRightDenyReason
      objectType
      subObjectType
      hasAccess
    }
  }
`;

export const useModuleRights = () => {
  const rights = useContext(ModuleRightsContext);
  if (rights === undefined) {
    throw new Error(
      'useModuleRights need to be used within a ModuleRightsContext provider'
    );
  }
  return rights;
};

export const ModuleRightsProvider: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (loading: boolean, rights: any[]) => React.ReactNode;
}> = (props) => {
  const { loading, data, error } = useQuery(MODULE_RIGHTS_QUERY);
  const ctx = useMemo(() => {
    return {
      rights: loading || error ? empty : data.getModuleAccessRights,
      loading,
    };
  }, [data?.getModuleAccessRights, loading, error]);
  return (
    <ModuleRightsContext.Provider value={ctx}>
      {props.children(ctx.loading, ctx.rights)}
    </ModuleRightsContext.Provider>
  );
};
