import htmlToPdfmake from 'html-to-pdfmake';
import { DateTime } from 'luxon';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {
  Content,
  DynamicContent,
  TDocumentDefinitions,
} from 'pdfmake/interfaces';
import { useCallback, useEffect, useState } from 'react';

import { blobToBase64 } from '@work4all/data/lib/utils';

import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { currencyAsSign, formatNumberAsCurrency } from '@work4all/utils';

import { SimpleReportSettings } from '../../../../../../../settings/settings';

import { useAddressBlock } from './hooks/use-address-block';
import { useDefaultFooter } from './hooks/use-default-footer';
import { useDefaultHeader } from './hooks/use-default-header';
import { useSimpleModeTranslation } from './translations/use-simple-mode-translation';
import {
  bzObjToEntityType,
  getBodyContent,
  nonCalculationErpPositionTypes,
  nonTotalPriceErpPositionTypes,
  PDFMAKE_TABLE_LAYOUTS,
  SimpleReportErpTypes,
  titleErpPositionTypes,
  useVatTiers,
} from './utils';

const HTML_PDFMAKE_OPT = {
  ignoreStyles: ['font-family', 'background-color', 'font-size'],
};

export type SimpleReportAddressOptions = {
  senderName?: string;
  senderStreet?: string;
  senderCity?: string;
  senderPostalCode?: string;
};

export type SimpleReportFooterOptions = {
  tel?: string;
  fax?: string;
  vatId?: string;
  margin?: number[];
};

export type SimpleReportOptions = {
  footer?: SimpleReportFooterOptions;
  address?: SimpleReportAddressOptions;
};

export const useSimpleReport = (props: {
  bzObjType: BzObjType;
  data: SimpleReportErpTypes;
  options?: SimpleReportSettings;
  signatureImgBlob?: Blob;
  signatureName?: string;
}) => {
  const { data, bzObjType, options, signatureImgBlob, signatureName } = props;

  const entityType = bzObjToEntityType[bzObjType];

  const [pdfUrl, setPdfUrl] = useState<string>(null);
  const [pdfBase64, setPdfBase64] = useState<string>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob>(null);

  const defaultFooter = useDefaultFooter({ ...options });
  const defaultHeader = useDefaultHeader({
    ...options,
  });
  const addressBlock = useAddressBlock({
    address: data?.additionalAddress1Text,
    ...options,
  });

  const lang = data.language?.id === 0 ? 'de' : 'en';
  const { t } = useSimpleModeTranslation();

  const vatTiers = useVatTiers(data.positionList);

  const showPrices =
    options.positionShowPrices && bzObjType !== BzObjType.LIEFERSCHEIN;

  const generate = useCallback(async () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.tableLayouts = PDFMAKE_TABLE_LAYOUTS;

    const headerTextHtml = getBodyContent(data.rtfHeadText);
    const footerTextHtml = getBodyContent(data.rtfFooterText);

    const headTextMarkup = htmlToPdfmake(headerTextHtml, HTML_PDFMAKE_OPT);
    const footerTextMarkup = htmlToPdfmake(footerTextHtml, HTML_PDFMAKE_OPT);

    const dd: TDocumentDefinitions = {
      defaultStyle: {
        fontSize: 8,
      },
      pageMargins: [
        options.pageMarginLeft,
        options.pageMarginTop,
        options.pageMarginRight,
        options.pageMarginRight,
      ],
      header: defaultHeader as Content | DynamicContent,
      footer: defaultFooter as Content | DynamicContent,
      content: [
        {
          stack: [
            addressBlock,
            {
              columns: [
                {
                  width: '*',
                  stack: [
                    {
                      text:
                        t(
                          lang,
                          'ENTITY.' +
                            (entityType === Entities.invoice && data.value < 0
                              ? 'CREDIT'
                              : entityType.toUpperCase())
                        ) +
                        ' ' +
                        (entityType === Entities.contract
                          ? (data as Contract).contractNumber
                          : data.number),
                      fontSize: 12,
                      bold: true,
                    },
                  ],
                  marginBottom: 20,
                },
              ],
            },
            {
              columns: [
                options.metaShowClientNumber &&
                data?.businessPartnerContactCombined?.businessPartner?.data
                  ?.number
                  ? {
                      width: '*',
                      stack: [
                        {
                          text: t(lang, 'CLIENT_NUMBER') + ':',
                        },
                        {
                          text: data?.businessPartnerContactCombined
                            ?.businessPartner?.data?.number,
                        },
                      ],
                    }
                  : null,

                options.metaShowContact
                  ? {
                      width: '*',
                      stack: [
                        {
                          text: t(lang, 'CONTACT') + ':',
                        },
                        {
                          text: data?.user?.displayName,
                        },
                      ],
                    }
                  : null,
                options.metaShowPhoneNumber
                  ? {
                      width: '*',
                      stack: [
                        {
                          text: t(lang, 'PHONE_NUMBER') + ':',
                        },
                        {
                          text: data?.user?.phoneNumber,
                        },
                      ],
                    }
                  : null,
                options.metaShowEMail
                  ? {
                      width: '*',
                      stack: [
                        {
                          text: t(lang, 'EMAIL') + ':',
                        },
                        {
                          text: data?.user?.eMail,
                        },
                      ],
                    }
                  : null,
                options.metaShowDate
                  ? {
                      width: '*',
                      alignment: 'right',
                      stack: [
                        {
                          text: t(lang, 'DATE') + ':',
                        },
                        {
                          text: DateTime.fromISO(
                            entityType === Entities.contract
                              ? (data as Contract)?.contractDate
                              : data?.date
                          ).toFormat('dd.MM.yyyy'),
                        },
                      ],
                    }
                  : null,
              ].filter((x) => Boolean(x)),
              fontSize: 6,
              bold: true,
              marginBottom: 10,
            },
            options.metaShowHeaderText && { stack: headTextMarkup },
            {
              layout: 'positionsList',
              marginTop: 20,
              marginBottom: 20,
              table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 1,
                widths: [
                  'auto',
                  '*',
                  'auto',
                  showPrices ? 'auto' : null,
                  showPrices ? 'auto' : null,
                ].filter(Boolean),
                body: [
                  [
                    {
                      text: t(lang, 'POS_LIST.POS_NUMBER'),
                      bold: true,
                    },
                    {
                      text: t(lang, 'POS_LIST.LONGTEXT'),
                      bold: true,
                    },
                    {
                      text: t(lang, 'POS_LIST.AMOUNT'),
                      bold: true,
                      alignment: 'right',
                    },
                    showPrices && {
                      text:
                        t(lang, 'POS_LIST.SINGLE_PRICE') +
                        '/' +
                        currencyAsSign(data.currency?.name),
                      bold: true,
                      alignment: 'right',
                    },
                    showPrices && {
                      text:
                        t(lang, 'POS_LIST.TOTAL_PRICE') +
                        '/' +
                        currencyAsSign(data.currency?.name),
                      bold: true,
                      alignment: 'right',
                    },
                  ].filter(Boolean),
                  ...data.positionList.map((pos: Position) =>
                    [
                      {
                        text: pos.number,
                        bold: titleErpPositionTypes.includes(pos.positionKind),
                      },
                      {
                        stack: [
                          pos.longtext,
                          options.positionShowAdditionalText && {
                            text: pos.internalText,
                            fontSize: 6,
                            marginTop: 4,
                          },
                        ].filter(Boolean),
                        bold: titleErpPositionTypes.includes(pos.positionKind),
                      },
                      {
                        text: !nonCalculationErpPositionTypes.includes(
                          pos.positionKind
                        )
                          ? pos.amount + ' ' + pos.unit
                          : '',
                        alignment: 'right',
                        bold: titleErpPositionTypes.includes(pos.positionKind),
                      },
                      showPrices && {
                        text: !nonCalculationErpPositionTypes.includes(
                          pos.positionKind
                        )
                          ? formatNumberAsCurrency(pos.singlePriceNet)
                          : '',
                        alignment: 'right',
                      },
                      showPrices
                        ? {
                            text: !nonTotalPriceErpPositionTypes.includes(
                              pos.positionKind
                            )
                              ? pos.insteadOfTotalPrice ||
                                formatNumberAsCurrency(pos.totalPriceNet)
                              : '',
                            alignment: 'right',
                            bold: titleErpPositionTypes.includes(
                              pos.positionKind
                            ),
                          }
                        : null,
                    ].filter(Boolean)
                  ),
                ].filter(Boolean),
              },
            },
            showPrices && {
              layout: 'summary',
              marginTop: 20,
              marginBottom: 20,
              table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 0,
                widths: ['*', 'auto'],
                body: [
                  [
                    {
                      text: t(lang, 'TOTAL_NET'),
                      bold: false,
                      alignment: 'right',
                    },
                    {
                      text:
                        formatNumberAsCurrency(data.value) +
                        ' ' +
                        currencyAsSign(data.currency?.name),
                      bold: false,
                      alignment: 'right',
                    },
                  ],
                  ...vatTiers.map((vat) => [
                    {
                      text: t(lang, 'VAT') + ' ' + vat.vatRate + '%',
                      bold: false,
                      alignment: 'right',
                    },
                    {
                      text:
                        formatNumberAsCurrency(vat.vatValue) +
                        ' ' +
                        currencyAsSign(data.currency?.name),
                      bold: false,
                      alignment: 'right',
                    },
                  ]),
                  [
                    {
                      text: t(lang, 'TOTAL_GROSS'),
                      bold: true,
                      alignment: 'right',
                    },
                    {
                      text:
                        formatNumberAsCurrency(
                          vatTiers
                            .map((x) => x.totalPriceGross)
                            ?.reduce((prev, next) => prev + next, 0)
                        ) +
                        ' ' +
                        currencyAsSign(data.currency?.name),
                      bold: true,
                      alignment: 'right',
                    },
                  ],
                ],
              },
            },
            showPrices && {
              stack: [
                {
                  text: t(lang, 'PAYMENT_TERMS'),
                  bold: true,
                },
                t(
                  lang,
                  'PAYMENT_TERMS_TEXT_' +
                    (data.priceGroup?.isGrossPrice ? 'GROSS' : 'NET'),
                  { days: data.paymentDeadline }
                ),
              ],
            },
            options.metaShowFooterText && { stack: footerTextMarkup },
            signatureImgBlob
              ? {
                  layout: 'positionsList',
                  marginTop: 50,
                  marginBottom: 20,
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 1,
                    widths: ['auto'].filter(Boolean),
                    body: [
                      [
                        {
                          image: await blobToBase64(signatureImgBlob),
                          width: 150,
                          alignment: 'center',
                        },
                      ],
                      [
                        {
                          text: `${DateTime.now().toFormat(
                            'dd.MM.yyyy'
                          )}, ${signatureName}`,
                          alignment: 'center',
                        },
                      ],
                    ],
                  },
                }
              : null,
          ].filter(Boolean),
        } as Content,
      ],
    };

    const pdfm = pdfMake.createPdf(dd);

    pdfm.getBlob((blob) => {
      setPdfUrl(URL.createObjectURL(blob));
      setPdfBlob(blob);
    });

    pdfm.getBase64(setPdfBase64);
  }, [
    addressBlock,
    data,
    defaultFooter,
    defaultHeader,
    entityType,
    lang,
    options.metaShowClientNumber,
    options.metaShowContact,
    options.metaShowDate,
    options.metaShowEMail,
    options.metaShowFooterText,
    options.metaShowHeaderText,
    options.metaShowPhoneNumber,
    options.pageMarginLeft,
    options.pageMarginRight,
    options.pageMarginTop,
    options.positionShowAdditionalText,
    showPrices,
    signatureImgBlob,
    signatureName,
    t,
    vatTiers,
  ]);

  useEffect(() => {
    generate();
  }, [generate, defaultHeader]);

  return { pdfUrl, pdfBase64, pdfBlob, generate };
};
