import styles from './OverviewItem.module.scss';

import { useEventCallback } from '@mui/material/utils';
import React, { useMemo } from 'react';

import { CountIndicator } from '@work4all/components/lib/dataDisplay/count-indicator/CountIndicator';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';
import { Body2 } from '@work4all/components/lib/typography/body2/Body2';

import {
  Link,
  useDataProvider,
  useRefetchOnEntityChanges,
} from '@work4all/data';

import { Work4AllEntity } from '@work4all/models/lib/additionalEnums/Work4AllEntity.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export interface IOverviewItemProps {
  title: string;
  entity: Entities;
  entityVariant?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  secondFilter?: any;
  deeplink?: string;
  editMode?: boolean;
  active?: boolean;
  onActiveChange?: (
    entity: Entities,
    value: boolean,
    entityVariant?: string
  ) => void;
  params?: { [key: string]: string };
}

function formatEntityPathname({
  entity,
  entityVariant,
}: {
  entity: Entities;
  entityVariant?: string | null;
}): string {
  if (!entityVariant) {
    return entity;
  }

  return `${entity}(${encodeURIComponent(entityVariant)})`;
}

export const OverviewItem: React.FC<IOverviewItemProps> = (props) => {
  const {
    title,
    entity,
    entityVariant,
    filter,
    deeplink,
    secondFilter,
    editMode,
    active,
    onActiveChange,
    params,
  } = props;
  const leftBubbleData = useOverviewItemData(entity, secondFilter);
  const rightBubbleData = useOverviewItemData(entity, filter);

  const refetch = useEventCallback(() => {
    leftBubbleData.refetch();
    rightBubbleData.refetch();
  });

  useRefetchOnEntityChanges({ entity, refetch });

  const linkText = <Body2 className={styles['title']}>{title}</Body2>;

  const URIParams = params ? `${new URLSearchParams(params).toString()}` : '';

  const firstDeeplinkURI = deeplink
    ? `/more/entity/${formatEntityPathname({
        entity,
        entityVariant,
      })}?settings=${deeplink}&${URIParams}`
    : null;

  return (
    <div className={styles['item-wrap']}>
      {!editMode && (
        <>
          {deeplink ? <Link to={firstDeeplinkURI}>{linkText}</Link> : linkText}
          <div className={styles.countIndicatorsContainer}>
            {rightBubbleData.total > 0 && (
              <Link className={styles.indicatorLink} to={firstDeeplinkURI}>
                <CountIndicator
                  value={rightBubbleData.total}
                  variant="secondary"
                />
              </Link>
            )}
          </div>
        </>
      )}
      {editMode && (
        <CheckboxRadioItem
          label={title}
          defaultChecked={active}
          className={styles.overviewEditItem}
          onChange={(e) => {
            onActiveChange(entity, e.target.checked, entityVariant);
          }}
        ></CheckboxRadioItem>
      )}
    </div>
  );
};

const lookUpTable: Partial<Record<Entities, Work4AllEntity>> = {
  appointment: {
    id: null,
    appointmentAttendeeList: [
      {
        id: null,
        userId: null,
      },
    ],
  },
};

const useOverviewItemData = (entity, filter) => {
  const providerData = useMemo(() => {
    return {
      entity: entity,
      data: lookUpTable[entity] || [],
      filter: filter,
    };
  }, [entity, filter]);

  const skipIfNoFilter = filter ? false : true;

  return useDataProvider(providerData, skipIfNoFilter);
};
